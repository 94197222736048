@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Jakarta";
    src: url("/PlusJakartaSans-Bold.woff2") format("woff2"), url("/PlusJakartaSans-Bold.woff") format("woff");
    font-display: block;
}

@layer base {
    body {
        @apply text-grey;
    }

    /** Firefox */
    summary {
        cursor: pointer;
        list-style: none;
        user-select: none;
    }

    /** Chrome, Safari Browsers */
    summary::-webkit-details-marker {
        display: none;
    }
}

@layer utilities {

    .article-html h1 {
        @apply text-3xl font-normal leading-normal;
    }

    .article-html h2 {
        @apply text-2xl font-normal leading-normal;
    }

    .flex-center {
        @apply flex items-center justify-center;
    }

    .hide-scrollbar::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }

    .hide-scrollbar {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
    }

    .show-scrollbar::-webkit-scrollbar {
        display: unset;
    }

    .show-scrollbar {
        scrollbar-width: unset; /* Firefox */
        -ms-overflow-style: unset; /* IE 10+ */
    }

    .thin-scrollbar {
        scrollbar-width: thin; /* Firefox */
        scrollbar-color: rgba(0, 0, 0, .2) hsla(0, 0%, 100%, .1);
        -ms-overflow-style: -ms-autohiding-scrollbar; /* IE 10+ */
    }

    .thin-scrollbar::-webkit-scrollbar {
        width: 6px; /* Chrome Safari */
    }

    .thin-scrollbar::-webkit-scrollbar-track {
        background: hsla(0, 0%, 100%, .1);
    }

    .thin-scrollbar::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, .2);
        border-radius: 0;
    }

    .animation-delay-300 {
        animation-delay: 300ms;
    }

    .animation-delay-600 {
        animation-delay: 600ms;
    }

    .animation-delay-900 {
        animation-delay: 900ms;
    }

    .shape-inset-r-1\/5 {
        shape-outside: inset(0px 20% 0px 0px);
        shape-margin: 1rem;
    }
}

@layer components {
    .button-white {
        @apply rounded border border-solid border-grey-400 p-2 text-xs font-semibold text-grey-700;
        @apply transition-colors hover:border-blue-900 hover:text-blue-900;
    }
    .button-text {
        @apply text-grey hover:text-blue-900 text-center;
        @apply p-2 sm:p-3 text-sm;
        @apply cursor-pointer select-none appearance-none;
        @apply focus:outline-none;
        @apply transition-colors;
        @apply disabled:opacity-25 disabled:pointer-events-none;
        @apply font-semibold;
    }

    .button {
        @apply button-text rounded-lg;
        @apply py-2 px-4 md:py-3 md:px-5;
        @apply bg-blue hover:bg-blue-900;
        @apply text-white hover:text-white;
    }

    .button-lg {
        @apply button text-sm md:text-base;
        @apply py-3 md:py-[18px] px-4 md:px-9;
    }

    .button-outline {
        @apply button-text rounded-lg;
        @apply border border-blue text-blue;
        @apply hover:text-blue-900 hover:border-blue-900;
    }

    .button-icon {
        @apply appearance-none focus:outline-none flex-center rounded-lg border cursor-pointer;
        @apply md:w-12 md:h-12 w-9 h-9;
        @apply border-grey-400 text-grey-600;
        @apply hover:border-blue-900 hover:text-blue-900;
        @apply transition-colors;
        @apply disabled:opacity-25 disabled:pointer-events-none;
    }

    .hover-orange {
        @apply bg-orange hover:bg-orange-900;
    }

    .spinner {
        @apply animate-spin h-7 w-7 border-grey-600 border rounded-full border-t-purple mx-auto;
    }

    .button-spinner {
        @apply animate-spin h-4 w-4 border-white border-2 rounded-full border-t-transparent inline-block;
    }

    .input {
        @apply bg-white;
        @apply border border-grey-500 rounded-lg;
        @apply hover:bg-grey-100;
        @apply focus:outline-none focus:border-blue focus:bg-grey-100;
        @apply placeholder-grey-600;
        @apply transition-colors;
        @apply px-3 py-2 w-full h-11;
        -webkit-text-size-adjust: none;
        text-size-adjust: none;
    }

    .text-gradient {
        @apply bg-gradient-to-r from-blue-400 to-pink-400 bg-clip-text text-transparent;
        line-height: normal;
    }

    .dot {
        height: 25px;
        width: 25px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
    }

    /* Safari Browser only */
    @supports (-webkit-hyphens:none) {
        .text-gradient {
            @apply text-grey;
        }
    }

    /* IOS only */
    @supports (-webkit-touch-callout: none) {
        .text-gradient {
            @apply text-grey;
        }
    }
}

.no-scroll {
    @apply overflow-hidden relative;
    touch-action: none;
}

@supports (-webkit-touch-callout: none) {
    .no-scroll {
        height: 100%;
        width: 100%;
        position: fixed;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

#nprogress .bar {
    @apply bg-purple;
}

#nprogress .peg {
    box-shadow: 0 0 10px theme("colors.purple.DEFAULT"), 0 0 5px theme("colors.purple.DEFAULT");
}
